
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";


interface IProps {
   dataList: any;
   riskyDataList: any;
}

const SensorTab2Page = (props: IProps) => {


    return(
        <>
            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="sh-fs-b4"
                    columns={[
                        {data: "valueHmdty", title: "습도"},
                        {data: "valueTmprtr", title: "온도"},
                        {data: "valueOxgn", title: "산소"},
                        {data: "valueHs", title: "황화수소"},
                        {data: "valueCo", title: "일산화탄소"},
                        {data: "valueMthn", title: "메탄"},
                        {data: "valueCo2", title: "이산화탄소"},
                        {data: "measureTime", title: "측정시간"}
                    ]}
                    index={true}
                    checked={false}
                    isNotPaging={true}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {

                    }
                    }
                />


            </div>


        </>
    )

}

export default SensorTab2Page;
