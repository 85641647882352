import React, { ChangeEvent, useState, useRef, useEffect, useContext } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as securityHelper from "../helper/SecurityHelper";
import {fn_loginHandlerCallback} from '../helper/CmmnHelper'
import { useCookies } from 'react-cookie';
import {setItemWithExpireTime, USER_SESSION} from "../helper/SecurityHelper";
import errorCodeDic from "../util/errorCodeDic";
import { UserContext } from '../context/User';
import {Col, Container, Form, Row} from "react-bootstrap";
import Logos from '../assets/images/logo/logo_sh.png';
import logo_title from '../assets/images/logo/sh_logo_light.png';
import logo_gsil from '../assets/images/logo/logo_gsli.png';
import uuid from "react-uuid";
import {toast} from "react-toastify";

export default function Login() {
  const navigate = useNavigate();
  const [validArea, setValidArea] = useState(true);
  const userIdReference = useRef<any>(null);
  const passwordReference = useRef(null);
  const user = securityHelper.getLoginUser();
  const [cookies, setCookie, removeCookie] = useCookies(['rememberUserId']);
  const [userId, setUserId] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const {setUser} = useContext<any>(UserContext);

  /*let urlParam:any = Object.fromEntries(new URLSearchParams(window.location.search));*/

  const fn_loginHandler = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    var userId = userIdReference.current.value
    // @ts-ignore
    var password = passwordReference.current.value

    if (userId.trim() === '') {
      alert("아이디를 입력해 주십시오");
      // @ts-ignore
      userIdReference.current.focus()
      return false;
    }
    var clientUuid = uuid()
    securityHelper.login(userId, password, clientUuid, async (user: any) => {
      if(user.isSuccess){
        //localStorage.setItem(USER_SESSION, JSON.stringify(user.userData));
        user.userData.accessClientUuid = clientUuid
        setItemWithExpireTime(USER_SESSION, user.userData);
        setUser(user.userData);
        fn_loginHandlerCallback(user, navigate)
      }else{
        alert(errorCodeDic[user.errorCode]);
      }
    });
    return false;
  };
  useEffect(() => {
    if(user){
      fn_loginHandlerCallback(user, navigate);
    }
    console.log('cookies.rememberUserId : ', cookies.rememberUserId );
    // 아이디 쿠키 저장
    if(cookies.rememberUserId !== undefined) {
      setUserId(cookies.rememberUserId);
      setIsRemember(true);
    }
  },[])
  const handleOnChange = (e: any) => {
    setIsRemember(e.target.checked);
    if(e.target.checked){
      setCookie('rememberUserId', userIdReference.current.value, {maxAge: 2000});
    } else {
      removeCookie('rememberUserId');
    }
  }

  const [imgUri, setImgUri] = useState('url(/bg_login_01.png)');

  return (
    <div className='w-screen h-screen'>
      <div className='sh-login'>
        <div
            className='w-screen h-screen position-absolute slide'
            style={{
              zIndex:-1,
              backgroundColor: '#191a1d',
              backgroundImage: 'url(/bg_new_login_001.jpeg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'top right',
              backgroundSize: 'cover'}} />
        <div
            className='w-screen h-screen position-absolute slide'
            style={{
              zIndex:-1,
              backgroundColor: '#191a1d',
              backgroundImage: 'url(/bg_new_login_002.jpeg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'top right',
              backgroundSize: 'cover'}} />
        <div
            className='w-screen h-screen position-absolute slide'
            style={{
              zIndex:-1,
              backgroundColor: '#191a1d',
              backgroundImage: 'url(/bg_new_login_003.jpeg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'top right',
              backgroundSize: 'cover'}} />
      </div>

      <Container className='d-flex justify-content-between align-items-center h-100'>
        <img src={Logos} alt="로고" className="absolute top-[70px] left-[80px] w-[300px] h-[55px]" />
        <Row>
          <Col bsPrefix="col-8">
            <div className="d-flex align-items-center h-100">
              <div>
                <div style={{fontSize: '3rem', fontWeight: '500'}}>위험을 보는 것이 안전의 시작입니다.</div>

              </div>
            </div>
          </Col>
          <Col bsPrefix="col-4">
            <div>
              <div className="sh-bg-white rounded-[10px] d-flex justify-content-center align-items-center" style={{height:'500px', boxShadow: '0 0 20px #777'}}>
                <div className="w-75">
                  <img src={logo_title} alt="로고" />
                  <Form>
                    <input
                        ref={userIdReference}
                        placeholder="아이디를 입력하세요."
                        defaultValue={userId}
                        className='w-full sh-form input mt-5 mb-2'
                        type='text'
                    />
                    <input
                        ref={passwordReference}
                        placeholder="비밀번호를 입력하세요."
                        className='w-full sh-form input'
                        type='password'
                    />
                    <div className='d-flex justify-content-between align-items-center mt-3 mb-4'>
                      <div className="d-flex align-items-center">
                        <input className='form-check-input sh-check me-2 mb-1' onChange={handleOnChange} checked={isRemember} type='checkbox' />
                        <div className='sh-fs-b3 sh-text-font_secondary01'>아이디 저장</div>
                      </div>
                      <div>
                        <div className='hover:cursor-pointer sh-fs-b3 sh-text-accent text-center underline underline-offset-2 decoration-[0.5px]'
                             onClick={() => navigate('/pwInquiry')}>아이디 찾기</div>
                      </div>
                    </div>
                    <LoginBtn clickHandler={fn_loginHandler} />
                  </Form>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                  <img  style={{width: 70}} src={logo_gsil} alt="로고" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const LoginBtn = ({ clickHandler }: any) => {
  return (
    <button type={"submit"}
        className='sh-btn normal p w-100 py-3 sh-fs-b1 my-3'
        onClick={clickHandler}
    >
      로그인 하기
    </button>
  );
};

const NfcBtn = ({ clickHandler }: any) => {
  return (
      <button className='mt-[1.25rem] font-preLight text-13 leading-18 w-full py-[0.5rem] md:text-18 outline-none bg-gradient-to-r from-gdNfcFrom to-gdNfcTo rounded-[5px]'
              onClick={clickHandler}>NFC태깅</button>
  );
};





