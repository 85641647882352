import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHSelect from "../../../components/common/SHSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import restapi from "../../../helper/restAPIHelper";
import SHDatePickerDeprecated from "../../../components/common/SHDatePickerDeprecated";
import MngrSrchModal from "./MngrSrchModal";
import {ProgressContext} from "../../../context/Progress";
import SHTextarea from "../../../components/common/SHTextarea";
import SHIconButton from "../../../components/common/SHIconButton";
import SHFile from "../../../components/common/SHFile";
import iconNodata from "../../../images/icon_nodocdata.png";
import {readonlyForm, validFormData} from "../../../helper/FormHelper";
import HeadSiteSearchModal from "./HeadSiteSearchModal";
import SftyApprCardList from "../../../components/appr/SftyApprCardList";
import ApprCardList from "../../../components/appr/ApprCardList";
import ApprLineModal from "../../siteSafetyManagement/modal/ApprLineModal";
import HeadApprLineModal from "./HeadApprLineModal";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import SHRadio from "../../../components/common/SHRadio";
import SHRadioGroup from "../../../components/common/SHRadioGroup";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    prcsGb: string;
    sprvDprtGb?: string;
}

const SiteSftyChkRgstModal = (props: IProps) => {
    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [sftyChkDt, setSftyChkDt] = useState<Date | null>(null);
    const [actCmpltDt, setActCmpltDt] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [isActDatePopupOpen, setIsActDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>({})
    const [hourList, setHourList] = useState<any>([]);
    const [minList, setMinList] = useState<any>([]);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<object[]>([])
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = useState<any>('');
    const [authCode, setAuthCode] = useState<any>('');
    const [mngrData, setMngrData] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const [siteSftyMeasuresData, setSiteSftyMeasuresData] = useState<any>();
    const [siteSftyMeasuresDataList, setSiteSftyMeasuresDataList] = useState<any>([]);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [imgTarget, setImgTarget] = useState<any>(null);
    const formRef = React.createRef<HTMLFormElement>();
    const actProdRef = useRef<any>();
    const [slctdRowIdx, setSlctdRowIdx] = useState<number>(0);
    const [cnfrmdInfoList, setCnfrmdInfoList] = useState<any>([]);
    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);

    useEffect(() => {
        if (blngSiteList.length > 0) {
            blngSiteList.map((siteData: any, idx) => {
                console.log('data: ', data);
                setData({
                    ...data,
                    siteId: siteData.siteId,
                    siteNm: siteData.siteName,
                    siteAddress: siteData.siteAddress,
                })
            })
        }
    }, [blngSiteList]);

    useEffect(() => {
        if (mngrData) {
            console.log('mngrData: ', mngrData);
            if (authCode == 'headOut') {
                setData({
                    ...data,
                    'extnlCommisnrSeq': mngrData.userSeq,
                    'extnlCommisnrNm': mngrData.userName,
                    'extnlCommisnrPhone': mngrData.userPhone
                })
            } else if (authCode == 'headPrvnt') {
                setData({
                    ...data,
                    'prvtSftyMngrSeq': mngrData.userSeq,
                    'prvtSftyMngrNm': mngrData.userName,
                    'prvtSftyMngrPhone': mngrData.userPhone
                })
            } else if (authCode == 'headBsns'){
                setData({
                    ...data,
                    'bsnsMngrSeq': mngrData.userSeq,
                    'bsnsMngrNm': mngrData.userName,
                    'bsnsMngrPhone': mngrData.userPhone
                })
            }


        }
    }, [mngrData]);

    useEffect(() => {
        console.log('siteSftyMeasuresDataList: ', siteSftyMeasuresDataList);
    }, [siteSftyMeasuresDataList]);

    useEffect(() =>{
        if(actCmpltDt){
            siteSftyMeasuresDataList[slctdRowIdx].actCmpltDt = actCmpltDt;
            setSiteSftyMeasuresDataList([].concat(siteSftyMeasuresDataList));
        }
    }, [actCmpltDt, slctdRowIdx])

    const fn_setFormData = (e: any , isWrkTpChg?: boolean) => {

        if(isWrkTpChg){
            setData({
                ...data,
                siteId: null,
                siteNm: '',
                siteAddress: '',
                [e.target.name]: e.target.value
            })
        }else{
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }



    }

    const fn_onInputSeChange = (e: any) => {

        setData({
            ...data,
            'extnlCommisnrSeq': null,
            'extnlCommisnrNm': null,
            'extnlCommisnrPhone': null,
            [e.target.name]: e.target.value
        })

    }

    const fn_setCmntSetFormData = (e: any, cmntGb: string) => {

        if(cmntGb == 'sftyChk'){
            setData({
                ...data,
                sftyCmntRgstrSeq: user.userSeq,
                sftyCmntRgstDt: CmmnFn.fn_getCurrentDateTime(),
                [e.target.name]: e.target.value
            })

        }else if(cmntGb == 'extnl'){
            setData({
                ...data,
                extnlCmntRgstrSeq: user.userSeq,
                extnlCmntRgstDt: CmmnFn.fn_getCurrentDateTime(),
                [e.target.name]: e.target.value
            })
        }
    }


    const fn_onDprtChg = (e: any) => {
        setData({
            ...data,
            'bsnsMngrSeq': null,
            'bsnsMngrNm': '',
            'bsnsMngrPhone': '',
            [e.target.name]: e.target.value
        })
    }

    const fn_retrieveApprUserDataList = (detailData: any) => {

        restapi.retrieveSiteSftyApprDataList({sftyChkSeq: detailData.sftyChkSeq}).then((apprUserDataList) => {
            let cstmData: object[] = [];

            restapi.retrieveUserDataList({isHeadAppr: true}).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })
                    setApprLineDataList([]);
                } else {
                    let apprUserSeqList: any = [];
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData);

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                }

            })

        })


    }

    function afterOpenModal() {
        if (!props.detailData) {
            setData({sftyChkHour: '', sftyChkMin: '', sprvDprtGb: props.sprvDprtGb, inputSe: 'S018001'});
            setSftyChkDt(null);
            setBlngSiteList([]);
            setSiteSftyMeasuresDataList([]);
            setApprLineDataList([]);
            setCnfrmdInfoList([]);
            // 상세
        } else {
            console.log('props.detailData: ', props.detailData);

            props.detailData.siteNm = props.detailData.siteName;
            setData(props.detailData);
            fn_retrieveApprUserDataList(props.detailData);
            setSftyChkDt(DateHelper.parse(props.detailData.sftyChkDt))

            restapi.retrieveSiteSftyMeasuresDataList(props.detailData.sftyChkSeq).then((rsltData) => {
                setSiteSftyMeasuresDataList(rsltData);
            })


            let cnfrmdInfoList: any = [];
            if(props.detailData.isBsnsCnfrmd){
                cnfrmdInfoList.push({
                     dprtNm: props.detailData.bsnsCnfrmdDprtNm
                    ,userName: props.detailData.bsnsCnfrmdUserNm
                    ,apprStatNm: '결과 확인 완료'
                    ,prcsDt: props.detailData.bsnsCnfrmdTime
                })
            }
            if(props.detailData.isPrvntCnfrmd){
                cnfrmdInfoList.push({
                    dprtNm: '예방 안전부'
                    ,userName: props.detailData.prvntCnfrmdUserNm
                    ,apprStatNm: '최종 확인 완료'
                    ,prcsDt: props.detailData.prvntCnfrmdTime
                })
            }

            setCnfrmdInfoList(cnfrmdInfoList);

        }
        fn_genHour();
        fn_genMin();
    }



    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };
    const fn_actDatePopupOpen = (idx: number) => {
        console.log('idx: ', idx);
        setSlctdRowIdx(idx);
        setIsActDatePopupOpen(!isActDatePopupOpen);
    }

    const fn_openAddSitePop = () => {
        if(!data.workTypeCd){
            toast.warn('우선 공종을 선택해 주십시오.');
            return;
        }

        setIsSitePopOpend(true);
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    useEffect(() => {
        if (blngSiteList.length > 0) {
            console.log('blngSiteList: ', blngSiteList);
        }
    }, [blngSiteList])

    const fn_registData = () => {
        console.log('data: ', data);

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.workTypeCd) {
            toast.warn('공종을 선택해 주십시오.');
            return;
        }

        if (!data.siteId) {
            toast.warn('현장을 선택해 주십시오.');
            return;
        }

        if (!sftyChkDt) {
            toast.warn('점검일자를 선택해 주십시오');
            return;
        }

        if (!data.sftyChkHour || !data.sftyChkMin) {
            toast.warn('점검시간을 선택해 주십시오');
            return;
        }

        if(data.sprvDprtGb == 'C006001'){
            // if (!data.extnlCommisnrSeq) {
            //     toast.warn('외부위원을 선택해 주십시오');
            //     return;
            // }
            //
            // if (!data.prvtSftyMngrSeq) {
            //     toast.warn('예방안전부 담당자를 선택해 주십시오');
            //     return;
            // }
        }else if(data.sprvDprtGb == 'C006002'){
            if (!data.bsnsMngrSeq){
                toast.warn('담당자를 선택해 주십시오');
                return;
            }
        }


        let param = data;
        let bfActFileList: any = [];
        let afActFileList: any = [];

        let cfrmMsg = '저장 하시겠습니까?';

        if(!data.sftyChkSeq){
            param.sftyChkGbCd = 'C003001';
        }

        if(props.prcsGb && props.prcsGb == 'actRgst'){
            param.siteSftyMeasuresDataList = siteSftyMeasuresDataList;
            param.actCmpltUserSeq = user.userSeq;
            param.actCmpltCmpnySeq = user.blngCmpnySeq;
            param.actStatGbCd = 'C005003';
            param.sftyChkGbCd = 'C003003';
            let isValid = true;

            siteSftyMeasuresDataList.map((data: any, idx: number) => {
                if (data.afActFile) {
                    afActFileList.push(data.afActFile)
                }
                if (!data.measuresAfAttachSeq && !data.fileAfTmpSrc) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 조치후 사진을 입력해 주십시오');
                    isValid = false;
                }
                if (!data.actCmpltDt) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 조치완료일을 선택해 주십시오');
                    isValid = false;
                }
                if (!data.actCntnts) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 조치내용을 입력해 주십시오');
                    isValid = false;
                }

                // 조치 완료

            });
            if (!isValid) {
                return;
            }
        }
        else if (data.sprvDprtGb == 'C006002' || (props.prcsGb && props.prcsGb == 'rsltRgst')) {

            if(siteSftyMeasuresDataList.length == 0){
                cfrmMsg = '안전점검 조치대상이 없으면 이상없음 처리 됩니다. 계속 하시겠습니까?';
            }

            param.siteSftyMeasuresDataList = siteSftyMeasuresDataList;
            let isValid = true;
            siteSftyMeasuresDataList.map((data: any, idx: number) => {
                if (data.bfActFile) {
                    bfActFileList.push(data.bfActFile)
                }
                if (!data.measuresGbCd) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 구분을 선택해 주십시오');
                    isValid = false;
                }
                if (!data.measuresComments) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 내용을 입력해 주십시오');
                    isValid = false;
                }
                if (!data.measuresBfAttachSeq && !data.fileTmpSrc) {
                    toast.warn('조치대상 ' + (idx + 1) + '의 조치전 사진을 입력해 주십시오');
                    isValid = false;
                }

            });
            if (!isValid) {
                return;
            }

            if (param.siteSftyMeasuresDataList.length == 0) {
                // 이상 없음
                param.actStatGbCd = 'C005001';
            } else {
                // 조치 권고
                param.actStatGbCd = 'C005002';
            }

            param.sftyChkRsltRgstDt = CmmnFn.fn_getCurrentDate();

            param.sftyChkGbCd = 'C003003';
        }


        console.log('siteSftyMeasuresDataList: ', siteSftyMeasuresDataList);


        if (!window.confirm(cfrmMsg)) {
            return;
        }


        param.sftyChkDt = DateHelper.format(sftyChkDt);
        if(user.authGrpId == 'HEAD'){
            param.rgstrSeq = user.userSeq;
        }

        param.updtrSeq = user.userSeq;
        console.log('param: ', param);


        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        if (bfActFileList.length > 0) {
            for (var i = 0; i < bfActFileList.length; i++) {
                formData.append('bfActFileList', bfActFileList[i]);
            }

        }
        if (afActFileList.length > 0) {
            for (var i = 0; i < afActFileList.length; i++) {
                formData.append('afActFileList', afActFileList[i]);
            }

        }

        if(props.sprvDprtGb){
            param.sprvDprtGb = props.sprvDprtGb;
        }
        formData.set('siteChkInfo', new Blob([JSON.stringify(param)], {type: "application/json"}));

        console.log('bfActFileList: ', formData.get('bfActFileList'));

        spinner.start();




        axios.post('/admin/api/siteSftyManage/saveSiteSftyManageData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_sftyChkConfirmData = () => {

        if (!window.confirm('점검승인 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.sftyChkGbCd = "C003001";
        param.updtrSeq = user.userSeq;

        axios.post('/admin/api/siteSftyManage/confirmSiteSftyManageData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    const fn_genHour = () => {
        let hours = [];
        let hr;

        for (var i = 1; i <= 24; i++) {
            if (i < 10) {
                hr = '0' + i;
            } else {
                hr = i;
            }
            hours.push(hr);
        }
        setHourList(hours);
    }

    const fn_genMin = () => {
        let min = [];
        let mi;

        for (var i = 0; i <= 60; i = i + 5) {
            if (i < 10) {
                mi = '0' + i;
            } else {
                mi = i;
            }
            min.push(mi);
        }
        setMinList(min);
    }

    const fn_openMngrPop = (title: string, authCode: string) => {

        if(authCode == 'headBsns'){
            if(!data.dprtCd){
                toast.warn('우선 부서를 선택해 주십시오');
                return;
            }
        }
        setModalTitle(title);
        setAuthCode(authCode);
        setModalIsOpen(true);
    }

    const fn_addSiteSftyMeasures = () => {
        let param: any = {};
        console.log('props.detailData: ', props.detailData);
        console.log('param: ', param);
        param.isMeasures = false;
        param.rgstrSeq = user.userSeq;


        siteSftyMeasuresDataList.push(param);
        setSiteSftyMeasuresDataList([].concat(siteSftyMeasuresDataList));

    }

    const fn_updateSiteSftyMeasuresDataList = (e: any, rowIdx: number) => {
        let cstmSiteSftyMeasuresDataList: any = siteSftyMeasuresDataList;
        cstmSiteSftyMeasuresDataList.map((data: any, idx: number) => {
            if (idx == rowIdx) {
                data[e.target.name] = e.target.value;
            }
        })

        setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
    }

    const fn_delRow = (rowIdx: number) => {
        let param = siteSftyMeasuresData;

        siteSftyMeasuresDataList.splice(rowIdx, 1);
        setSiteSftyMeasuresDataList([].concat(siteSftyMeasuresDataList));
    }

    const fn_uploadActBfImg = (e: any, data: any, idx: number) => {
        e.preventDefault();
        setImgTarget({idx: idx, gb: 'bf'});
        console.log(111);
        fileRef.current.click();
    }

    const fn_uploadActAfImg = (e: any, data: any, idx: number) => {
        e.preventDefault();
        setImgTarget({idx: idx, gb: 'af'});
        console.log(222);
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmSiteSftyMeasuresDataList = siteSftyMeasuresDataList;

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget.gb == 'bf') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].bfActFile = file;

                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;
                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
            } else if (imgTarget.gb == 'af') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileAfTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].afActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;

                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));

                console.log('cstmSiteSftyMeasuresDataList: ', cstmSiteSftyMeasuresDataList);
            }
        }

    }

    const fn_setSiteSftyMsrData = (e: any, idx: number) => {
        siteSftyMeasuresDataList[idx][e.target.name] = e.target.value;
        setSiteSftyMeasuresDataList([].concat(siteSftyMeasuresDataList));
    }

    const fn_bsnsCnfrm = () => {
        console.log('user: ', user);
        /*if(user.authCode != 'headBsns'){
            toast.warn('사업부 소속 관리자만 결과확인이 가능 합니다.')
            return;
        }*/

        if(!window.confirm('결과확인 하시겠습니까?')){
            return;
        }

        spinner.start();
        restapi.updateCnfrmStat({sftyChkSeq: data.sftyChkSeq, isBsnsCnfrmd: true, bsnsCnfrmdUserSeq: user.userSeq, isFinalCnfrm:false}).then((rsltCnt: any) => {
            if(rsltCnt > 0){
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_prvntCnfrm = () => {

        if(user.authCode != 'headPrvnt'){
            toast.warn('예방 안전부 소속 관리자만 결과확인이 가능 합니다.')
            return;
        }

        if(!window.confirm('최종완료확인 하시겠습니까?')){
            return;
        }

        spinner.start();
        restapi.updateCnfrmStat({sftyChkSeq: data.sftyChkSeq, isPrvntCnfrmd: true, prvntCnfrmdUserSeq: user.userSeq, isFinalCnfrm:true}).then((rsltCnt: any) => {
            if(rsltCnt > 0){
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let apprStatCd = '';

        if(apprUserSeqList.length == 1){
            apprStatCd = 'S001004';
        }else{
            apprStatCd = 'S001002';
        }

        spinner.start();
        axios.post('/admin/api/siteSftyManage/rqstAppr', {
            sftyChkSeq: props.detailData.sftyChkSeq,
            apprUserSeqList: apprUserSeqList,
            apprStatCd:apprStatCd
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {sftyChkSeq: props.detailData.sftyChkSeq};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/siteSftyManage/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'
        }

        if (!window.confirm(msg)) {
            return;
        }

        axios.post(url, param).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/siteSftyManage/cancelAppr', {
            sftyChkSeq: props.detailData.sftyChkSeq,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    return (
        <div>
            <HeadSiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend} blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteType={data?.workTypeCd}/>
            <MngrSrchModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} modalTitle={modalTitle}
                           authCode={authCode} setMngrData={setMngrData} sprvDprtGb={data.sprvDprtGb} dprtCd={data.dprtCd}></MngrSrchModal>
            <HeadApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                           mngrUserDataList={mngrUserDataList}
                           apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                           setApprLineDataList={setApprLineDataList}/>


            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                {isActDatePopupOpen && props.prcsGb === 'actRgst'
                    ? <div className='absolute top-200 left-1/3'>
                        <CustomDatePicker setIsDatePopupOpen={setIsActDatePopupOpen}
                                          prodRef={actProdRef}
                                          setDateView={setActCmpltDt}
                        />
                    </div>
                    : null}
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        현장안전점검 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} onSubmit={submit} encType="multipart/form-data" method={"post"}>

                        { (props.sprvDprtGb == 'C006002' && props.detailData) &&
                        <div className="sh-modal-div">
                            <div className="div-header sh-fw-5">
                                <div className={'min-w-[100px] font-semibold'}>
                                    결재 정보
                                </div>
                                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                    {(props.detailData.actStatGbCd == 'C005003' && props.detailData?.rgstrSeq == user.userSeq && (!props.detailData.apprStatCd || props.detailData.apprStatCd == 'S001001')) &&
                                        <>
                                            <SHIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                setIsApprLineModalOpen(true)
                                            }}> 결재선
                                            </SHIconButton>
                                            <SHIconButton kind={'normal'} marginRight={'5px'}
                                                          onClick={fn_rqstAppr}> 결재요청
                                            </SHIconButton>
                                        </>
                                    }
                                    {
                                        apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                        <>
                                            <button className={'sh-btn mr-5 btn-primary'} onClick={() => {
                                                fn_appr('appr')
                                            }}> 결재
                                            </button>

                                        </>
                                    }

                                    {(props.detailData.apprStatCd == 'S001002' && (props.detailData?.rgstrSeq == user.userSeq || apprLineDataList.filter((data:any) => (data.userSeq == user.userSeq)).length > 0 )) &&
                                        <SHIconButton kind={'normal'} marginRight={'5px'}
                                                      onClick={fn_cancelRqst}> 회수
                                        </SHIconButton>
                                    }

                                    {(props.sprvDprtGb == 'C006002' && props.detailData?.actStatGbCd == 'C005003' && props.detailData?.apprStatCd == 'S001004' && props.detailData?.isPrvntCnfrmd == false) &&
                                        <SHIconButton kind={'normal'} marginRight={'5px'}
                                                      onClick={fn_prvntCnfrm}> 최종확인
                                        </SHIconButton>

                                    }


                                </div>


                            </div>

                            <div className="div-body px-3">
                                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                </div>
                                <div className="w-100">
                                    {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                        <span>※ 결재정보를 등록해 주십시오.</span>
                                    }

                                </div>
                            </div>
                        </div>

                        }


                        {cnfrmdInfoList.length > 0 &&
                            <SftyApprCardList items={cnfrmdInfoList}></SftyApprCardList>
                        }
                        <div className="sh-modal-div">
                            <div className="div-header">
                                기본정보
                                {(props.sprvDprtGb == 'C006001' && (props.detailData?.actStatGbCd == 'C005003' || props.detailData?.actStatGbCd == 'C005001') && props.detailData?.isBsnsCnfrmd == false) &&
                                    <button className={'sh-btn mr-5 btn-secondary'} onClick={fn_bsnsCnfrm}> 결과확인
                                    </button>
                                }
                                {(props.sprvDprtGb == 'C006001' && props.detailData?.actStatGbCd == 'C005003' && props.detailData?.isBsnsCnfrmd == true && props.detailData?.isPrvntCnfrmd == false) &&
                                    <button className={'sh-btn mr-5 btn-secondary'} onClick={fn_prvntCnfrm}> 최종완료확인
                                    </button>
                                }

                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} name={'workTypeCd'} codeGb={'cmmn'}
                                                      codeGrpId={'T002'} disabled={props.prcsGb === 'actRgst'}
                                                      onChange={(e) => {fn_setFormData(e, true);}} className={'form-select'}
                                                      value={data.workTypeCd}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장명</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control"
                                                         name={'siteNm'}
                                                         onChange={fn_setFormData} value={data?.siteNm}/>
                                                <SHInput type="hidden" className="form-control" name={'siteId'}
                                                         onChange={fn_setFormData} value={data?.siteId}/>
                                                {props.prcsGb !== 'actRgst' &&
                                                    <button className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10"
                                                            type="button" onClick={fn_openAddSitePop}>검색
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장주소</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHInput type="text" disabled={true} className="form-control"
                                                         name={'siteAddress'}
                                                         onChange={fn_setFormData} value={data?.siteAddress}/>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>점검일시</th>
                                        <td style={{width: '35%'}} ref={prodRef}>
                                            <div className="d-flex align-items-center w-100">
                                                <SHDatePicker2 setter={setSftyChkDt} name={'sftyChkDt'} defaultValue={sftyChkDt} onChange={fn_setFormData}/>
                                                &nbsp;
                                                <select className={'form-select select sh-select-w-auto'} value={data?.sftyChkHour}
                                                        onChange={fn_setFormData} name={'sftyChkHour'}
                                                         disabled={props.prcsGb === 'actRgst'}>
                                                    <option value={''}>시</option>
                                                    {
                                                        hourList.map((data: string, idx: number) => (
                                                            <option key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                                &nbsp;
                                                <select className={'form-select select sh-select-w-auto'} name={'sftyChkMin'}
                                                        onChange={fn_setFormData} value={data?.sftyChkMin}
                                                         disabled={props.prcsGb === 'actRgst'}>
                                                    <option value={''}>분</option>
                                                    {
                                                        minList.map((data: string, idx: number) => (
                                                            <option key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {data.sprvDprtGb === 'C006001' ?
                            <>
                                <div className="sh-modal-div">
                                    <div className="div-header">
                                        외부위원 정보
                                    </div>
                                    <div className="div-body px-3">
                                        <Table className="my-2 align-middle">
                                            <tbody>
                                            <tr>
                                                <th className="sh-bg-th" style={{width: '15%'}}>입력구분</th>
                                                <td colSpan={3} style={{textAlign: 'left', paddingBottom: '10px'}}>
                                                    <SHRadioGroup>
                                                        <SHRadio name="inputSe" value={'S018001'} checked={data && data.inputSe === 'S018001'} onChange={(e: any) => {fn_onInputSeChange(e);}} >
                                                            <span className={'text-black font-semibold'}>검색</span>
                                                        </SHRadio>
                                                        <SHRadio name="inputSe" className={'ml-10'} value={'S018002'} checked={data&& data.inputSe === 'S018002'} onChange={(e: any) => {fn_onInputSeChange(e);}}>
                                                            <span className={'text-black font-semibold'}>직접입력</span>
                                                        </SHRadio>
                                                    </SHRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                            <th className="sh-bg-th" style={{width: '15%'}}>이름</th>
                                                <td style={{width: '35%'}}>
                                                    <div className="d-flex align-items-center w-100">
                                                        <SHInput
                                                            type="text"
                                                            className="form-control"
                                                            readOnly={data.inputSe !== 'S018002'}
                                                            name="extnlCommisnrNm"
                                                            onChange={fn_setFormData}
                                                            value={data?.extnlCommisnrNm}
                                                        />
                                                        {(props.prcsGb !== 'actRgst' && data.inputSe !== 'S018002') && (
                                                            <button
                                                                className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10"
                                                                onClick={() => fn_openMngrPop('외부위원 검색', 'headOut')}
                                                                type="button"
                                                            >
                                                                검색
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>

                                                <th className="sh-bg-th" style={{width: '15%'}}>핸드폰번호</th>
                                                <td style={{width: '35%'}}>
                                                    <div className="d-flex align-items-center w-100">
                                                        <SHInput
                                                            type="text"
                                                            className="form-control"
                                                            readOnly={data.inputSe !== 'S018002'}
                                                            name="extnlCommisnrPhone"
                                                            onChange={fn_setFormData}
                                                            value={data?.extnlCommisnrPhone}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="sh-modal-div">
                                    <div className="div-header">
                                        예방안전부 담당자 정보
                                    </div>
                                    <div className="div-body px-3">
                                        <Table className="my-2 align-middle">
                                            <tbody>
                                            <tr>
                                                <th className="sh-bg-th" style={{width: '15%'}}>이름</th>
                                                <td style={{width: '35%'}}>
                                                    <div className="d-flex align-items-center w-100">
                                                    <SHInput type="text" className="form-control" readOnly={true}
                                                                 name={'prvtSftyMngrNm'}
                                                                 onChange={fn_setFormData}
                                                                 value={data?.prvtSftyMngrNm}/>
                                                        {props.prcsGb !== 'actRgst' &&
                                                            <button
                                                                className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10"
                                                                onClick={() => fn_openMngrPop('예방안전부 담당자 검색', 'headPrvnt')}
                                                                type="button">검색
                                                            </button>
                                                        }
                                                    </div>

                                                </td>

                                                <th className="sh-bg-th" style={{width: '15%'}}>핸드폰번호</th>
                                                <td style={{width: '35%'}}>
                                                    <div className="d-flex align-items-center w-100">
                                                        <SHInput type="text" className="form-control" readOnly={true}
                                                                 name={'prvtSftyMngrPhone'}
                                                                 onChange={fn_setFormData}
                                                                 value={data?.prvtSftyMngrPhone}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </> :
                            <div className="sh-modal-div">
                                <div className="div-header">
                                    담당자 정보
                                </div>
                                <div className="div-body px-3">
                                    <Table className="my-2 align-middle">
                                        <tbody>
                                        <tr>
                                            <th className="sh-bg-th" style={{width: '15%'}}>부서</th>
                                            <td style={{width: '35%'}}>
                                                <SHSelect initOptionGb={'sel'} name={'dprtCd'} codeGb={'cmmn'}
                                                          codeGrpId={'D001'} disabled={props.prcsGb === 'actRgst'}
                                                          onChange={fn_onDprtChg} className={'form-select'}
                                                          value={data.dprtCd}></SHSelect>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>이름</th>
                                            <td style={{width: '35%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                    <SHInput type="text" className="form-control" readOnly={true} name={'bsnsMngrNm'}
                                                             onChange={fn_setFormData} value={data?.bsnsMngrNm}/>
                                                    {props.prcsGb !== 'actRgst' &&
                                                        <button className="sh-btn btn-secondary min-w-[50px] ml-5 mr-10"
                                                                onClick={() => fn_openMngrPop('사업부 담당자 검색', 'headBsns')}
                                                                type="button">검색
                                                        </button>
                                                    }
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }


                        {(data?.sprvDprtGb == 'C006002' || 'rsltRgst' == props.prcsGb || (props.prcsGb == 'actRgst' && props.detailData?.sftyChkGbCd == 'C003003')) &&
                            <>
                                <div className="sh-modal-div">
                                    <div className="div-header">
                                        안전점검 결과 입력
                                    </div>
                                    <div className="div-body px-3">
                                        <Table className="my-2 align-middle">
                                            <tbody>
                                            <tr className="border-top align-middle">
                                                <th className="sh-bg-th" style={{width: '15%'}}>의견 등록</th>
                                                <td style={{width: '85%'}} colSpan={3}>
                                                    <div className="w-100 p-0 sh-fs-b3">
                                                        {data.sftyCmntRgstrNm && <span className={'font-semibold'}>{data.sftyCmntRgstrNm + '  ' + data.sftyCmntRgstDt}</span>}
                                                        <SHTextarea rows={3} onChange={(e: any) => fn_setCmntSetFormData(e, 'sftyChk')}
                                                                    name={'sftyChkComments'}
                                                                    disabled={props.prcsGb == 'actRgst'}
                                                                    value={data?.sftyChkComments}
                                                                    className={"form-control"}
                                                                    placeholder='점검자 의견을 입력해 주십시오'/>

                                                    </div>
                                                </td>
                                            </tr>
                                            {data.sprvDprtGb == 'C006001' &&
                                                <tr className="border-top align-middle">
                                                    <th className="sh-bg-th" style={{width: '15%'}}>외부위원 의견 등록</th>
                                                    <td style={{width: '85%'}} colSpan={3}>
                                                        <div className="w-100 p-0 sh-fs-b3">
                                                            {data.extnlCmntRgstrNm && <span className={'font-semibold'}>{data.extnlCmntRgstrNm + '  ' + data.extnlCmntRgstDt}</span>}
                                                            <SHTextarea rows={3} onChange={(e: any) => fn_setCmntSetFormData(e, 'extnl')}
                                                                        name={'extnlCommisnrComments'}
                                                                        value={data?.extnlCommisnrComments}
                                                                        disabled={props.prcsGb == 'actRgst'}
                                                                        className={"form-control"}
                                                                        placeholder='외부위원 의견을 입력해 주십시오'/>

                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="sh-modal-div under mb-2">
                                    <div className="div-header">
                                        <div>
                                            안전점검 조치대상
                                        </div>
                                        {props.prcsGb !== 'actRgst' &&
                                            <SHIconButton kind={'add'}
                                                          onClick={fn_addSiteSftyMeasures}>추가</SHIconButton>
                                        }
                                    </div>
                                    <div>


                                        {
                                            siteSftyMeasuresDataList.length > 0 ?
                                                siteSftyMeasuresDataList.map((siteSftyMeasuresData: any, idx: number) => (

                                                    <Container className='sh-box-form py-2 mt-3' key={idx}>
                                                        <Row className="font-semibold mb-1">
                                                            <Col>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <div>{'조치대상 ' + (idx + 1)}</div>
                                                                    {props.prcsGb !== 'actRgst' &&
                                                                        <SHIconButton kind={'delete'} onClick={() => {
                                                                            fn_delRow(idx)
                                                                        }}>삭제</SHIconButton>}

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                        <Row>
                                                            <Col>
                                                                <span className="sh-fw-5">조치전</span>
                                                            </Col>
                                                            <Col>
                                                                <span className="sh-fw-5">조치후</span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {siteSftyMeasuresData?.fileTmpSrc ?
                                                                    <img className={'cursor-pointer max-h-[300px]'}
                                                                         onClick={(e) => fn_uploadActBfImg(e,siteSftyMeasuresData, idx)}
                                                                         src={siteSftyMeasuresData.fileTmpSrc}/>
                                                                    : siteSftyMeasuresData.measuresBfAttachSeq ?
                                                                        <img
                                                                            className={props.prcsGb !== 'actRgst' ? 'cursor-pointer max-h-[300px]' : 'max-h-[300px]'}
                                                                            onClick={(e) => props.prcsGb !== 'actRgst' && fn_uploadActBfImg(e,siteSftyMeasuresData, idx)}
                                                                            src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + siteSftyMeasuresData.measuresBfAttachSeq + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                                        :
                                                                        <label htmlFor="input-file"
                                                                               className={'sh-btn btn-secondary w-[100px] cursor-pointer'}
                                                                               onClick={(e) => fn_uploadActBfImg(e,siteSftyMeasuresData, idx)}>
                                                                            사진 업로드
                                                                        </label>
                                                                }
                                                                <span className="sh-fw-5">구분</span>
                                                                <SHSelect initOptionGb={'sel'}
                                                                          name={'measuresGbCd'} codeGb={'cmmn'}
                                                                          codeGrpId={'C004'}
                                                                          className={'form-select max-w-[153px]'}
                                                                          disabled={props.prcsGb == 'actRgst' ? true : false}
                                                                          onChange={(e) => {
                                                                              fn_updateSiteSftyMeasuresDataList(e, idx)
                                                                          }}
                                                                          value={siteSftyMeasuresData?.measuresGbCd}></SHSelect>
                                                                <span className="sh-fw-5">내용</span>
                                                                <SHInput className="sh-form input"
                                                                         name={'measuresComments'}
                                                                         onChange={(e) => {
                                                                             fn_updateSiteSftyMeasuresDataList(e, idx)
                                                                         }}
                                                                         disabled={props.prcsGb == 'actRgst' ? true : false}
                                                                         value={siteSftyMeasuresData?.measuresComments}
                                                                />


                                                            </Col>
                                                            <Col>
                                                                {siteSftyMeasuresData?.fileAfTmpSrc ?
                                                                    <img className={'cursor-pointer max-h-[300px]'}
                                                                         onClick={(e) => fn_uploadActAfImg(e, siteSftyMeasuresData, idx)}
                                                                         src={siteSftyMeasuresData.fileAfTmpSrc}/>
                                                                    : siteSftyMeasuresData.measuresAfAttachSeq ?
                                                                        <img
                                                                            className={props.prcsGb !== 'actRgst' ? 'cursor-pointer max-h-[300px]' : 'max-h-[300px]'}
                                                                            onClick={(e) => props.prcsGb !== 'actRgst' && fn_uploadActAfImg(e, siteSftyMeasuresData, idx)}
                                                                            src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + siteSftyMeasuresData.measuresAfAttachSeq + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                                        : props.prcsGb === 'actRgst' &&
                                                                        <label htmlFor="input-file"
                                                                               className={'sh-btn btn-secondary w-[100px] cursor-pointer'}
                                                                               onClick={(e) => fn_uploadActAfImg(e, siteSftyMeasuresData, idx)}>
                                                                            사진 업로드
                                                                        </label>
                                                                }

                                                                {['C005002', 'C005003'].includes(props.detailData?.actStatGbCd) &&
                                                                    <>
                                                                        <span className="sh-fw-5">조치완료일</span>
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-between"
                                                                            ref={actProdRef}>
                                                                            <SHDatePickerDeprecated
                                                                                className={"text-center"}
                                                                                onClick={() => fn_actDatePopupOpen(idx)}
                                                                                calendar={() => fn_actDatePopupOpen(idx)}
                                                                                value={siteSftyMeasuresData.actCmpltDt} name={'actCmpltDt'}
                                                                                readOnly={true}/>
                                                                        </div>
                                                                        <span className="sh-fw-5">조치내용</span>
                                                                        <SHInput name={'actCntnts'} onChange={(e) => fn_updateSiteSftyMeasuresDataList(e, idx)}
                                                                                 className={'form-control'}
                                                                                 value={siteSftyMeasuresData.actCntnts || ''}></SHInput>
                                                                    </>
                                                                }


                                                            </Col>
                                                        </Row>
                                                    </Container>


                                                )) :
                                                <div
                                                    className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                                    <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>

                                                    <span
                                                        className="sh-text-font_secondary02 sh-fw-5 mt-2">{props.prcsGb == 'actRgst' ? '조치대상이 없습니다.' : '조치대상을 추가하세요.'}</span>

                                                </div>
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성자 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.regDate || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {(!['C005001', 'C005003'].includes(props.detailData?.actStatGbCd) && user.authCode != 'headRead'
                            && !(props.prcsGb === 'actRgst' && ['C003001','C003002'].includes(props.detailData?.sftyChkGbCd))) && <button onClick={fn_registData}
                                                                                                                                          className='sh-btn pill p'>{(props.detailData?.actStatGbCd == 'C005002' && props.prcsGb === 'actRgst') ? '조치완료' : '등록/수정'}
                        </button>
                        }

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SiteSftyChkRgstModal;

