import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Container} from "@mui/material";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHTextarea from "../../../components/common/SHTextarea";
import SHSelect from "../../../components/common/SHSelect";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";
import SHIconButton from "../../../components/common/SHIconButton";
import IconRemove from "../../../images/icon_remove.png";
import EduPtcptSrchModal from "./EduPtcptSrchModal";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const SafetyEduRgstModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [eduDt, setEduDt] = useState<Date | null>(null);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);

    const [data, setData] = useState<any>()
    const [selected, setSelected] = useState([])
    const [eduTimeList, setEduTimeList] = useState<any>([]);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const [eduPtcptList, setEduPtcptList] = useState<any>([]);
    const [isEduPtcptPopOpen, setIsEduPtcptPopOpen] = useState(false);
    const [e001DataList, setE001DataList] = useState<any>([]);

    const fn_setFormData = (e: any) => {
        if(e.target.name == 'eduGbCd'){
            setData({
                ...data,
                [e.target.name]: e.target.value,
                ['eduCntnts']: e001DataList.filter((data: any) => data.code == e.target.value)[0]?.desc
            })
        }else{
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }


    }

    function afterOpenModal() {
        CmmnFn.loadCmmnCdData('E001').then((resultData: any) => {
            console.log('e001datalist: ', resultData);
            setE001DataList(resultData);
        })

        if (!props.detailData) {
            console.log('props.siteId: ', props.siteId);
            setData({siteId: props.siteId, cmpnySeq: user.blngCmpnySeq})
            setEduDt(null)
            setSelected([])
            setEduPtcptList([]);
            // 상세
        } else {
            setData(props.detailData);
            setEduDt(DateHelper.parse(props.detailData.eduDt))
            restapi.retrieveEduPtcptDataList({eduId: props.detailData.eduId}).then((rsltData: any) => {
                setEduPtcptList(rsltData);
            })
        }
        fn_genEduTime();
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('업체를 선택해 주십시오.')
            return;
        }

        if(!data.eduTitle) {
            toast.warn('교육제목을 입력해 주십시오.');
            return;
        }

        if (!data.eduGbCd) {
            toast.warn('교육종류를 선택해 주십시오.');
            return;
        }

        if (!data.tchrNm) {
            toast.warn('강사성명을 입력해 주십시오.');
            return;
        }

        if (!eduDt) {
            toast.warn('교육일자를 선택해 주십시오.');
            return;
        }

        if (!data.eduStrtTime) {
            toast.warn('교육시작 시간을 입력해 주십시오.');
            return;
        }

        if (!data.eduEndTime) {
            toast.warn('교육종료 시간을 입력해 주십시오.');
            return;
        }

        if (data.eduStrtTime > data.eduEndTime) {
            toast.warn('교육 종료시간이 시작시간 이전일 수 없습니다.');
            return;
        }

        if (!data.eduPlc) {
            toast.warn('교육장소를 입력해 주십시오.');
            return;
        }

        if (!data.eduCntnts) {
            toast.warn('교육내용을 입력해 주십시오.')
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.eduDt = DateHelper.format(eduDt);
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.eduPtcptList = eduPtcptList;

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        if(param.atchFile){
            formData.set('atchFile', param.atchFile);
            delete param.atchFile;
        }

        formData.set('eduRgstInfo', new Blob([JSON.stringify(param)], {type: "application/json"}))
        spinner.start();
        axios.post('/admin/api/edu/saveEduData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
                props.setIsSaveSuccess(true);
            } else {
                toast.error(errorCodeDic['003']);
                props.setIsSaveSuccess(false);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    const fn_genEduTime = () => {

        let date = new Date(2023, 0, 1);
        let timeArr = ['00:00']
        let hr;
        let mi;

        for (var i = 0; i < 47; i++) {
            date.setMinutes(date.getMinutes() + 30);
            hr = date.getHours() + '';
            mi = date.getMinutes() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            timeArr.push(hr + ':' + mi);
        }

        setEduTimeList(timeArr);


    }

    const fn_uploadImg = () => {
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        let cstmData = data;
        if(cstmData === null){
            cstmData = {}
        }
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);

            cstmData.fileTmpSrc = imgSrc;
            cstmData.atchFile = file;
            cstmData.isPhotoUpload = true;
            setData(Object.assign({}, cstmData));
        }
    }

    const fn_delEduPtcptRow = (idx: number) => {
        eduPtcptList.splice(idx, 1);
        setEduPtcptList([].concat(eduPtcptList));

    }

    const fn_wrkrModalOpen = () => {

        setIsEduPtcptPopOpen(true);
    }

    return (
        <div>
            <EduPtcptSrchModal setModalIsOpen={setIsEduPtcptPopOpen} modalIsOpen={isEduPtcptPopOpen} wrkrDataList={eduPtcptList}
                           setWrkrDataList={setEduPtcptList} siteId={props.siteId}/>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        안전교육 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                교육정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control w-100'}
                                                      value={data?.siteId}></SHSelect>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육일자</th>
                                        <td style={{width: '35%'}} ref={prodRef}>
                                            <SHDatePicker2 setter={setEduDt} name={'eduDt'} defaultValue={eduDt} onChange={fn_setFormData} fitWidth={true} />
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육제목</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput name={'eduTitle'} className={'form-control'} value={data?.eduTitle} onChange={fn_setFormData}></SHInput>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육종류</th>
                                        <td style={{width: '35%'}}>
                                            <SHSelect initOptionGb={'sel'} className={'w-100'} name={'eduGbCd'}
                                                      codeGb={'cmmn'} codeGrpId={'E001'}
                                                      onChange={fn_setFormData}
                                                      value={data?.eduGbCd}></SHSelect>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육대상</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'eduTrgt'}
                                                     onChange={fn_setFormData} value={data?.eduTrgt}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>강사성명</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'tchrNm'}
                                                     onChange={fn_setFormData} value={data?.tchrNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육장소</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'eduPlc'}
                                                     onChange={fn_setFormData} value={data?.eduPlc}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>직급</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'position'}
                                                     onChange={fn_setFormData} value={data?.position}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육시간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <select className={'form-select'} value={data?.eduStrtTime || ''}
                                                        onChange={fn_setFormData} name={'eduStrtTime'}
                                                        >
                                                    <option className={'text-center'} value={''}>시작 시간</option>
                                                    {
                                                        eduTimeList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }

                                                </select>
                                                <select className={'form-select'} name={'eduEndTime'}
                                                        onChange={fn_setFormData}
                                                        value={data?.eduEndTime || ''} >
                                                    <option className={'text-center'} value={''}>종료 시간</option>
                                                    {
                                                        eduTimeList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>비고</th>
                                        <td style={{width: '35%'}}>
                                            <SHInput type="text" className="form-control" name={'bigo'}
                                                     onChange={fn_setFormData} value={data?.bigo}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">


                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>교육내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <SHTextarea rows={5} onChange={fn_setFormData} name={'eduCntnts'}
                                                        value={data?.eduCntnts}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                <div>교육 참석자 명단</div>
                                <SHIconButton kind={'add'} onClick={fn_wrkrModalOpen}>교육 참석자 등록</SHIconButton>
                            </div>
                            <div className="div-body px-3">
                                <Table className="sub my-2 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle w-[20%]">No</th>
                                        <th className="align-middle w-[20%]">이름</th>
                                        <th className="align-middle w-[20%]">업체</th>
                                        <th className="align-middle w-[20%]">공종</th>
                                        <th className="align-middle w-[12%]">직종</th>
                                        <th className="align-middle w-[8%]">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        eduPtcptList.length > 0 ?
                                            eduPtcptList.map((data: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td className={'align-content-start'}>
                                                        {data.userName}
                                                    </td>
                                                    <td className={'align-content-start'}>
                                                        {data.companyName}
                                                    </td>
                                                    <td className={'align-middle'}>
                                                        {data.siteWrkTpNm}
                                                    </td>
                                                    <td className={'align-middle'}>
                                                        {data.siteOcptNm}
                                                    </td>
                                                    <td>
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block'}
                                                            src={IconRemove} onClick={() => {
                                                            fn_delEduPtcptRow(idx)
                                                        }}/>
                                                    </td>
                                                </tr>
                                            ))


                                            : <tr>
                                                <td colSpan={6}>
                                                    <div className="text-center">
                                                        명단에 포함된 교육 참석자가 없습니다.
                                                    </div>
                                                </td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="sh-modal-div under mb-2">
                            <div className="div-header">
                                <div>
                                    교육사진
                                </div>
                            </div>
                            <div>
                                <Container className='sh-box-form py-2 mt-3'>

                                    <Row>
                                        <Col>
                                            {data?.fileTmpSrc ?
                                                <img className={'cursor-pointer'}
                                                     onClick={() => fn_uploadImg()}
                                                     src={data.fileTmpSrc}/>
                                                : data?.atchFileId ?
                                                    <img
                                                        className={user.userSeq == data?.rgstrSeq ? 'cursor-pointer' : ''}
                                                        onClick={() => user.userSeq == data?.rgstrSeq && fn_uploadImg()}
                                                        src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data?.atchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                    :
                                                    <label htmlFor="input-file"
                                                           className={'sh-btn btn-secondary w-[100px] cursor-pointer'}
                                                           onClick={() => fn_uploadImg()}>
                                                        사진 업로드
                                                    </label>
                                            }


                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>

                        <div className="sh-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>


                            </div>

                        </div>


                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button
                            className='sh-btn pill p' onClick={fn_registData}>저장
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SafetyEduRgstModal;

