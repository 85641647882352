import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import axios, {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import SHInput from "../../../components/common/SHInput";
import SHSelect from "../../../components/common/SHSelect";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import SHTextarea from "../../../components/common/SHTextarea";
import SHIconButton from "../../../components/common/SHIconButton";
import iconNodata from "../../../images/icon_nodocdata.png";
import SHFile from "../../../components/common/SHFile";
import SHDatePicker2 from "../../../components/common/SHDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const responseBody = (response: AxiosResponse) => response.data;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: any, option: any) =>
        axios.post(url, body, option).then(responseBody),
    put: (url: string, body: object, option: any) =>
        axios.put(url, body, option).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};
const SiteSftyChkRsltModal = (props: IProps) => {
    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [sftyChkDt, setSftyChkDt] = useState<Date | null>(null);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [hourList, setHourList] = useState<any>([]);
    const [minList, setMinList] = useState<any>([]);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<object[]>([])
    const [siteSftyMeasuresData, setSiteSftyMeasuresData] = useState<any>();
    const [siteSftyMeasuresDataList, setSiteSftyMeasuresDataList] = useState<any>([]);
    const [afAtchFileList, setAfAtchFileList] = useState([]);
    const [bfAtchFileList, setBfAtchFileList] = useState([]);

    useEffect(() => {
        setSiteSftyMeasuresData({sftyChkSeq: props.detailData?.sftyChkSeq})
    }, [])

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function afterOpenModal() {
        setData(props.detailData);
        setSftyChkDt(DateHelper.parse(props.detailData.sftyChkDt))
        fn_genHour();
        fn_genMin();
        callApi.retrieveSiteSftyMeasuresDataList(props.detailData.sftyChkSeq).then((rsltData) => {
            setSiteSftyMeasuresDataList(rsltData);
        })
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_addSiteSftyMeasures = () => {
        let param = siteSftyMeasuresData;
        param.sftyChkSeq = props.detailData.sftyChkSeq;
        param.isMeasures = false;
        param.rgstrSeq = user.userSeq;

        axios.post('/admin/api/siteSftyManage/saveSiteSftyMeasuresData', param).then((result) => {
            if (result.data > 0) {
                callApi.retrieveSiteSftyMeasuresDataList(props.detailData.sftyChkSeq).then((rsltData) => {
                    setSiteSftyMeasuresDataList(rsltData);
                })
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    const fn_delRow = (sftyChkMeasuresSeq: number) => {
        let param = siteSftyMeasuresData;
        param.sftyChkSeq = props.detailData.sftyChkSeq;
        param.sftyChkMeasuresSeq = sftyChkMeasuresSeq
        param.updtrSeq = user.userSeq;

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/siteSftyManage/deleteSiteSftyMeasuresData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['002'])
                callApi.retrieveSiteSftyMeasuresDataList(props.detailData.sftyChkSeq).then((rsltData) => {
                    setSiteSftyMeasuresDataList(rsltData);
                })
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    const fn_updateSiteSftyMeasuresDataList = (e: any, rowIdx: number) => {
        let cstmSiteSftyMeasuresDataList: any = siteSftyMeasuresDataList;
        cstmSiteSftyMeasuresDataList.map((data: any, idx: number) => {
            if (idx == rowIdx) {
                data[e.target.name] = e.target.value;
            }
        })

        setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.workTypeCd) {
            toast.warn('공종을 선택해 주십시오.');
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.siteSftyMeasuresDataList = siteSftyMeasuresDataList;

        axios.post('/admin/api/siteSftyManage/saveSiteSftyRsltManageData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    /**
     * 현장안전조치 목록 조회
     * @param userSeq
     */
    const callApi =  {
        retrieveSiteSftyMeasuresDataList: (sftyChkSeq: number) =>
            requests.post('/admin/api/siteSftyManage/retrieveSiteSftyMeasuresDataList',
                {sftyChkSeq:sftyChkSeq},
                null
            ),
    };

    const fn_sftyChkConfirmData = () => {

        if (!window.confirm('점검승인 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.sftyChkGbCd = "C003001";
        param.updtrSeq = user.userSeq;

        axios.post('/admin/api/siteSftyManage/confirmSiteSftyManageData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })
    }

    const fn_genHour = () => {
        let hours = [];
        let hr;

        for (var i = 1; i <= 24; i++) {
            if (i < 10) {
                hr = '0' + i;
            } else {
                hr = i;
            }
            hours.push(hr);
        }
        setHourList(hours);
    }

    const fn_genMin = () => {
        let min = [];
        let mi;

        for (var i = 0; i <= 60; i = i + 5) {
            if (i < 10) {
                mi = '0' + i;
            } else {
                mi = i;
            }
            min.push(mi);
        }
        setMinList(min);
    }

    return (
        <div>
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend} blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true}/>
            <Modal
                className="sh-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                        현장안전점검 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'} onSubmit={submit}>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>공종</th>
                                            <td style={{width: '35%'}}>
                                                <SHSelect initOptionGb={'sel'} disabled={true} name={'workTypeCd'} codeGb={'cmmn'} codeGrpId={'T002'}
                                                          onChange={fn_setFormData} className={'form-control'}
                                                          value={data?.workTypeCd}></SHSelect>
                                            </td>
                                            <td style={{width: '35%'}} colSpan={2} />
                                        </tr>
                                        <tr>
                                            <th className="sh-bg-th" style={{width: '15%'}}>현장명</th>
                                            <td style={{width: '35%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                    <SHInput type="text" disabled={true} className="form-control" name={'siteNm'}
                                                             onChange={fn_setFormData} value={data?.siteName}/>
                                                    <SHInput type="hidden" className="form-control" name={'siteId'}
                                                             onChange={fn_setFormData} value={data?.siteId}/>
                                                </div>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>현장주소</th>
                                            <td style={{width: '35%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                    <SHInput type="text" disabled={true} className="form-control" name={'siteAddress'}
                                                             onChange={fn_setFormData} value={data?.siteAddress}/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>점검계획</th>
                                            <td style={{width: '35%'}} ref={prodRef}>
                                                <SHDatePicker2 setter={setSftyChkDt} name={'sftyChkDt'} defaultValue={sftyChkDt} disabled={true} onChange={fn_setFormData}/>
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                    <select className={'form-select'} value={data?.sftyChkHour}
                                                            onChange={fn_setFormData} name={'sftyChkHour'} disabled={true}
                                                            >
                                                        <option className={'text-center'} value={''}>시간</option>
                                                        {
                                                            hourList.map((data: string, idx: number) => (
                                                                <option className={'text-center'} key={idx}
                                                                        value={data}>{data}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </td>
                                            <td style={{width: '25%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                    <select className={'form-select'} name={'sftyChkMin'} disabled={true}
                                                            onChange={fn_setFormData} value={data?.sftyChkMin}
                                                            >
                                                        <option className={'text-center'} value={''}>분</option>
                                                        {
                                                            minList.map((data: string, idx: number) => (
                                                                <option className={'text-center'} key={idx}
                                                                        value={data}>{data}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                안전점검 결과 입력
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>의견 등록</th>
                                            <td style={{width: '85%'}} colSpan={3}>
                                                <SHTextarea rows={3} onChange={fn_setFormData} name={'sftyChkComments'}
                                                            value={data?.sftyChkComments}
                                                            className={"form-control"}
                                                            placeholder='내용을 입력해주세요.'/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>외부위원 의견 등록</th>
                                            <td style={{width: '85%'}} colSpan={3}>
                                                <SHTextarea rows={3} onChange={fn_setFormData} name={'extnlCommisnrComments'}
                                                            value={data?.extnlCommisnrComments}
                                                            className={"form-control"}
                                                            placeholder='내용을 입력해주세요.'/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                안전점검 조치대상
                                <SHIconButton kind={'add'} onClick={fn_addSiteSftyMeasures}>추가</SHIconButton>
                            </div>
                            <div>
                                {
                                    siteSftyMeasuresDataList.length > 0 ?
                                        siteSftyMeasuresDataList.map((siteSftyMeasuresData: any, idx: number) => (
                                            <div className="sh-modal-div" key={idx}>
                                                <div className="div-header sh-fw-5">

                                                    <SHIconButton kind={'delete'} onClick={() => {
                                                        fn_delRow(siteSftyMeasuresData.sftyChkMeasuresSeq)
                                                    }}>삭제</SHIconButton>
                                                </div>
                                                <div className="div-body p-3">
                                                    <Container className='sh-box-form gray py-2 mb-3'>
                                                        <Row>
                                                            <Col>
                                                                <span className="sh-fw-5">구분</span>
                                                                <SHSelect initOptionGb={'sel'} name={'measuresGbCd'} codeGb={'cmmn'} codeGrpId={'T002'}
                                                                          className={'form-control'} onChange={(e) => {
                                                                            fn_updateSiteSftyMeasuresDataList(e, idx)
                                                                          }}
                                                                          value={siteSftyMeasuresData?.measuresGbCd}></SHSelect>
                                                            </Col>
                                                            <Col>
                                                                <span className="sh-fw-5">내용</span>
                                                                <SHInput className="sh-form input" name={'measuresComments'} onChange={(e) => {
                                                                            fn_updateSiteSftyMeasuresDataList(e, idx)
                                                                         }}
                                                                         value={siteSftyMeasuresData?.measuresComments}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <span className="sh-fw-5">조치전</span>
                                                                <div>
                                                                    <SHFile fileName={'afAtchFileList'} fileList={afAtchFileList} setFileList={setAfAtchFileList}></SHFile>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <span className="sh-fw-5">조치후</span>
                                                                <div>
                                                                    <SHFile fileName={'bfAtchFileList'} fileList={bfAtchFileList} setFileList={setBfAtchFileList}></SHFile>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        )) :
                                        <div
                                            className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                            <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                            <span className="sh-text-font_secondary02 sh-fw-5 mt-2">조치대상을 추가하세요.</span>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="sh-modal-div">
                            <div className="div-header">
                                담당자 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="sh-bg-th" style={{width: '15%'}}>작성일</th>
                                            <td style={{width: '35%'}}>
                                                <input value={data?.rgstDt || currentDate}
                                                       className={"form-control"}
                                                       disabled={true}/>
                                            </td>
                                            <th className="sh-bg-th" style={{width: '15%'}}>작성자</th>
                                            <td style={{width: '35%'}}>
                                                <input className={"form-control"}
                                                       value={data?.rgstrNm || user.userName} disabled={true}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='sh-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='sh-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SiteSftyChkRsltModal;

